import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";
import { EffectCards} from "swiper/modules";
import Navbar from "../components/GalleryNavbar";
import Navigation from "../components/navigation.jsx";
import FrostEffect from "../components/FrostEffectSecond";
import Footer from "../components/Footer";
import image1 from "../assets/img/Catering_Gallery/1.jpeg";
import image2 from "../assets/img/Catering_Gallery/2.jpeg";
import image3 from "../assets/img/Catering_Gallery/3.jpeg";
import image4 from "../assets/img/Catering_Gallery/4.jpeg";
import image5 from "../assets/img/Catering_Gallery/5.jpeg";
import image6 from "../assets/img/Catering_Gallery/6.jpeg";
import image7 from "../assets/img/Catering_Gallery/7.jpeg";
import image8 from "../assets/img/Catering_Gallery/8.jpeg";
import image9 from "../assets/img/Catering_Gallery/9.jpeg";
import image10 from "../assets/img/Catering_Gallery/10.jpeg";
import image11 from "../assets/img/Catering_Gallery/11.jpeg";
import "./gallery.css"; // Assuming your CSS file is named gallery.css

function Gallery() {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const images = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11]; // Update with all your image imports

  function toggleFullscreen(index) {
    setCurrentImageIndex(index);
    setIsFullscreen(prevState => !prevState);
  }

  return (
    <div>
      {isFullscreen && (
        <div className="fullscreen flex justify-center items-center bg-black bg-opacity-80 fixed w-full h-full z-50"  onClick={toggleFullscreen}>
          <img
            className="w-[360px] h-[480px] md:h-[680px] md:w-[510px]" src={images[currentImageIndex]} alt="Fullscreen Image"
          />
        </div>
      )}
      <Navbar />
      <FrostEffect />
      <div className="flex flex-col items-center justify-center py-24 gap-6 overflow-x-hidden">
        <h1 className="text-4xl font-header text-white md:text-7xl md:py-10">
          Galerie
        </h1>
        <div className="p-10 pb-24 flex flex-col items-center gap-6 bg-black w-screen">
          <h2 className="font-body font-bold text-white text-3xl text-center md:text-5xl">
            Cateringy - Svatby - Oslavy - Večírky
          </h2>
          <Swiper effect={"cards"} modules={[EffectCards]} className="catering h-[320px] w-[240px] md:h-[480px] md:w-[360px]">
              <SwiperSlide className="rounded-3xl" onClick={() => toggleFullscreen(0)}></SwiperSlide>
              <SwiperSlide className="rounded-3xl" onClick={() => toggleFullscreen(1)}></SwiperSlide>
              <SwiperSlide className="rounded-3xl" onClick={() => toggleFullscreen(2)}></SwiperSlide>
              <SwiperSlide className="rounded-3xl" onClick={() => toggleFullscreen(3)}></SwiperSlide>
              <SwiperSlide className="rounded-3xl" onClick={() => toggleFullscreen(4)}></SwiperSlide>
              <SwiperSlide className="rounded-3xl" onClick={() => toggleFullscreen(5)}></SwiperSlide>
              <SwiperSlide className="rounded-3xl" onClick={() => toggleFullscreen(6)}></SwiperSlide>
              <SwiperSlide className="rounded-3xl" onClick={() => toggleFullscreen(7)}></SwiperSlide>
              <SwiperSlide className="rounded-3xl" onClick={() => toggleFullscreen(8)}></SwiperSlide>
              <SwiperSlide className="rounded-3xl" onClick={() => toggleFullscreen(9)}></SwiperSlide>
              <SwiperSlide className="rounded-3xl" onClick={() => toggleFullscreen(10)}></SwiperSlide>
            <Navigation />
          </Swiper>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Gallery;
