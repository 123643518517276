'use client'
import React, { useEffect, useState } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from "react-router-dom";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };
    const [header, setHeader] = useState(false);

    const scrollHeader = () =>{
        if(window.scrollY >= 500){
            setHeader(true)
        } else {
            setHeader (false)
        }
    }

    useEffect(()=>{
        window.addEventListener('scroll',scrollHeader)

        return ()=>{
            window.removeEventListener('scroll',scrollHeader)
        }
    })

  return (
    <div className={`flex z-20 justify-between items-center mt-6 h-11 lg:h-12 xl:h-14 max-w-[1400px] mx-auto px-4 fixed left-0 right-0 lg:w-[75%] xl:w-[60%] w-[70%] bg-white/40 text-white lg:ease-in-out duration-500 ${nav ? "rounded-t-2xl duration-[100ms]" : "rounded-2xl duration-[3000ms]"}`}>
      <Link to="/"><h1 className="w-full font-header text-sm md:text-xl lg:text-xl xl:text-2xl">Pochutnej Si! BISTRO</h1></Link>
      <ul className='hidden lg:flex'>
        <Link to="/"><li className={`p-4 font-bold rounded-lg ${header ? "hover:bg-white/40 hover:text-white" : " hover:bg-yellow hover:text-white"}`}>BISTRO</li></Link>
        <Link to="/menu"><li className={`p-4 font-bold rounded-lg ${header ? "hover:bg-white/40 hover:text-white" : " hover:bg-yellow hover:text-white"}`}>Menu</li></Link>
        <Link to="/Gallery"><li className={`p-4 font-bold rounded-lg ${header ? "bg-white/60 text-white" : "hover:bg-yellow hover:text-white"}`}>Galerie</li></Link>
        <Link to="/Contact"><li className={`p-4 font-bold rounded-lg ${header ? "hover:bg-white/40 hover:text-white" : " hover:bg-yellow hover:text-white"}`}>Kontakt</li></Link>
        <Link to="https://pochutnej-si.choiceqr.com/booking"><li className={`p-4 font-bold rounded-lg ${header ? "hover:bg-white/40 hover:text-white" : " hover:bg-yellow hover:text-white"}`}>Rezervace</li></Link>
      </ul>
      <div onClick={handleNav} className='block lg:hidden'>
          {nav ? <AiOutlineClose size={20}/> : <AiOutlineMenu size={20} />}
      </div>
      <ul className={nav ? "rounded-2xl ml-[-1rem] top-16 fixed w-[70%] duration-500 ease-in-out" : "hidden"}>
        <Link to="/"><li className={`py-2 text-center font-header border-b border-gray text-white bg-white/40 backdrop-blur-2xl hover:bg-yellow ${nav ? "opacity-100 ease-in-out duration-500" : "ease-in-out duration-500 delay-[400ms] opacity-0"}`}>BISTRO</li></Link>
        <Link to ="/menu"><li className={`py-2 text-center font-header border-b border-gray text-white hover:bg-yellow ${nav ? "opacity-100 ease-in-out duration-500 delay-[100ms] bg-white/40 backdrop-blur-2xl" : "ease-in-out duration-500 delay-[300ms] opacity-0"}`}>Menu</li></Link>
        <Link to="/gallery"><li className={`py-2 text-center font-header border-b border-gray text-white ${nav ? "opacity-100 ease-in-out duration-500 delay-[200ms] bg-white/40 backdrop-blur-2xl" : "ease-in-out duration-500 delay-[200ms] opacity-0"}`}>Galerie</li></Link>
        <Link to="/Contact"><li className={`py-2 text-center font-header border-b border-gray text-white hover:bg-yellow ${nav ? "opacity-100 ease-in-out duration-500 delay-[300ms] bg-white/40 backdrop-blur-2xl" : "ease-in-out duration-500 delay-[100ms] opacity-0"}`}>Kontakt</li></Link>
        <Link to="https://pochutnej-si.choiceqr.com/booking"><li className={`py-2 text-center font-header px-4 border-b border-gray text-white hover:bg-yellow ${nav ? "opacity-100 ease-in-out duration-500 delay-[400ms] bg-white/40  rounded-b-2xl backdrop-blur-2xl" : "ease-in-out duration-500 opacity-0"}`}>Rezervace</li></Link>
      </ul>
    </div>
  );
};

export default Navbar;